.pageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 50vh;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.container h2 {
  text-transform: uppercase;
  letter-spacing: 4px;
  margin: 20px 0px 0px 0px;
  font-size: 12px;
}

.signInContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.signInForm {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.link {
  color: white;
}

.signInForm button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 28px;
  border-radius: 5px;
  border-width: 0px;
  gap: 20px;
  color: white;
  background-color: #385178;
}

.signInForm input {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  border-radius: 5px;
  border-width: 0px;
  gap: 20px;
}

.signInButtonContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
}

.signInButtonContainer form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin: 0px;
}

.signInButtonContainer button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 28px;
  border-radius: 5px;
  border-width: 0px;
  gap: 20px;
  color: white;
  background-color: #385178;
}

.signInButtonContainer input {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  border-radius: 5px;
  border-width: 0px;
  gap: 20px;
}

.signOut {
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 28px;
  border-radius: 5px;
  border-width: 0px;
  gap: 20px;
  color: white;
  background-color: #385178;
}