
:root {
  --foreground-rgb: 255, 255, 255;
  --background-start-rgb: 47, 74, 113;
  --background-end-rgb: 116, 150, 201;
}



@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 47, 74, 113;
    --background-end-rgb: 116, 150, 201;
  }
}

body {
  overscroll-behavior: none;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: rgb(var(--foreground-rgb));
  min-height: 100vh;
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}


@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.background_logo {
  position: fixed;
  z-index: -1;
  left: 0px;
  top: 0px;
  opacity: 0.05;
  transform: scale(1.5) translate(-20%, 0);
}