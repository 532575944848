.pageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 50vh;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px 20px 20px;
  max-width: 400px;
}

.container h2 {
  text-transform: uppercase;
  letter-spacing: 4px;
  margin: 15px 0px 0px 0px;
  font-size: 12px;
}

.signInContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.signInForm {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.link {
  color: white;
}

.signInForm button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 28px;
  border-radius: 5px;
  border-width: 0px;
  gap: 20px;
  color: white;
  background-color: #385178;
  white-space: nowrap;
}

.signSpilt {
  display: flex;
  height: 10px;
}

.sendgridForm {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
  flex-basis: 100%;
}

.sendgridForm input {
  flex: 1;
  width: 100%;
}

.sendgridForm button {
  flex: 1;
  width: 100%;
}

.googleForm  {
  flex: 1;
}
.googleForm button  {
  flex: 1;
}

.githubForm {
  flex: 1;
}

.githubForm button {
  flex: 1;
}

.signInForm input {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 10px 10px;
  border-radius: 5px;
  border-width: 0px;
  flex: 1;
  flex-grow: 1;
  gap: 20px;
}

.signInButtonContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}

.signInButtonContainer form:first-child {
  gap: 10px;
}

.signInButtonContainer form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin: 0px;
}

.signInButtonContainer button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 28px;
  border-radius: 5px;
  border-width: 0px;
  gap: 20px;
  color: white;
  background-color: #385178;
}

.signInButtonContainer input {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 10px 10px;
  border-radius: 5px;
  border-width: 0px;
  gap: 20px;
}

.signOut {
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 28px;
  border-radius: 5px;
  border-width: 0px;
  gap: 20px;
  color: white;
  background-color: #385178;
}


@media (max-width: 900px) {
  .container {
    min-width: 300px;
    padding: 10px;
  }

  .container button {
    width: 100%;
  }

  .container input[type="text"], .container input[type="password"], .container input[type="email"] {
    width: 100%;
  }

  .signInForm {
    flex-direction: column;
  }

  .signInButtonContainer {
    flex-direction: column;
  }

  .signInButtonContainer form {
    display: flex;
    flex-direction: column;
  }
}