.signInButton {
  padding: 0;
  background-color: transparent;
  border-width: 0;
  color: white;
  text-transform: uppercase;
  letter-spacing: 8px;
  font-size: 12px;
  cursor: pointer;
}

.header {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;

}

.profileimage {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: #fff solid 1px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.profilelink {
  text-decoration: none; 
}

.profilelink div {
  display: flex;
  flex-direction: row;
  text-align: center;
  font-weight: 200;
  gap: 10px;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
  letter-spacing: 2px;
}

.profilelink a {
  text-decoration: none;
}


.logoContainer {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  margin-left: 70px;
  gap: 70px;
  text-align: center;
  justify-content: center;
}

.naviContainer {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  text-align: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;
}

.nav {
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.link {
  color: white;
  text-decoration: none;

  text-transform: uppercase;
  letter-spacing: 8px;
  font-size: 12px;

  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.logo {
  width: 70px;
  height: 100px;
}

.bioText {
  height: auto;
  min-height: 0;
  display: flex;
  flex-direction: row;

  align-items: center;

  justify-content: center;
  letter-spacing: 80px;
  font-size: 20px;
  font-weight: 800;
  margin-right: -80px;
}

.bioText, .nexioText {
  transition: color 1s, font-size 0.5s, letter-spacing 0.5s;
}

.nexioText {
  height: auto;
  min-height: 0;
  display: flex;
  flex-direction: column;
  gap: 10;
  text-align: center;
  justify-content: center;
  letter-spacing: 80px;
  font-size: 20px;
  font-weight: 800;
}

@media (max-width: 900px) {
  .bioText {
    font-size: 10px;
    letter-spacing: 20px;
    margin-right: -20px;
  }

  .nexioText {
    font-size: 10px;
    letter-spacing: 20px;
  }

  .logoContainer {
    gap: 30px;
    margin-left: 30px;
  }

  .naviContainer {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.personPicture {
  width: 90%;
  height: 90%;
  display: block;
  background-color: grey;
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.personPictureBorder {
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-sizing: border-box;
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

.personPictureContainer {
  flex: 1;
  flex-direction: column;
  height: 45px !important;
  width: 45px !important;
  display: flex;
  min-height: 100%;
}